import React from "react";
import { Grid, Container } from "basis";

import { useMerchantAuth } from "../../../core/auth";
import { LoadingMessage } from "../../../components";
import { NavigationalLayout, OrderForm } from "../components";

const Scene = () => {
  const { loading, profile: merchantProfile } = useMerchantAuth();

  if (loading) {
    return <LoadingMessage />;
  }

  return (
    <NavigationalLayout>
      <Container hasBreakpointWidth padding="8 0">
        <Grid preset="page">
          <Grid.Item colSpan="all">
            <OrderForm fetchedMerchantDetails={merchantProfile} />
          </Grid.Item>
        </Grid>
      </Container>
    </NavigationalLayout>
  );
};

export default Scene;
