import React, { useState } from "react";
import { PolarisProvider } from "../../../components";
import {
  Scene,
  TransactionSearch,
  TrainingCheck,
  TRANSACTION_FILTERS,
  DashboardFilter,
} from "../../../layout/merchant";

export default () => {
  const [selectedFilter, setSelectedFilter] = useState(TRANSACTION_FILTERS.ALL);
  const [selectedDates, setSelectedDates] = useState({
    start: null,
    end: null,
  });
  const [searchText, setSearchText] = useState("");
  return (
    <PolarisProvider>
      <Scene>
        <Scene.FullWidth>
          <DashboardFilter
            selectedFilter={selectedFilter}
            fromDate={selectedDates?.start}
            toDate={selectedDates?.end}
            searchText={searchText}
            onFilterChange={(val) => setSelectedFilter(val)}
            onSelectedDatesChange={(val) => setSelectedDates(val)}
            onSearchTextChange={(val) => setSearchText(val)}
          />
        </Scene.FullWidth>

        <Scene.FullWidth>
          <TrainingCheck>
            <TransactionSearch
              selectedFilter={selectedFilter}
              fromDate={selectedDates?.start}
              toDate={selectedDates?.end}
              searchText={searchText}
            />
          </TrainingCheck>
        </Scene.FullWidth>

      </Scene>
    </PolarisProvider>
  );
};
