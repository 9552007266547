import React from "react";
import { PortalBackground, Container, Text, Button } from "../../components";
import { MERCHANT_PAGE_ROUTES } from "../../core/constants";
import { redirect } from "../../core/navigate";
import { resetAuth } from "../../core/auth";

const Logout = () => {
  resetAuth()

  return (
    <PortalBackground centeredContent>
      <Container maxWidth={480} bg="white" padding="8" rounded shadow>
        <Text textStyle="heading4">Invalid session.</Text>

        <Text margin="4 0 10 0">
          You have been logged out because of an invalid session or you do not
          have access to the requested resource.
        </Text>

        <Text margin="4 0 10 0">
          Please log in again to continue or contact Latitude Support if problem
          persists.
        </Text>

        <Button showButton onClick={() => redirect(MERCHANT_PAGE_ROUTES.Login)}>
          Log back in
        </Button>
      </Container>
    </PortalBackground>
  );
};

export default Logout;
