import React from "react";
import { Grid, Text } from "basis";

import { Container, Tabs } from "../../../components";
import { AnalyticsDashboard, NavigationalLayout } from "../components";
import { ANALYTICS_DASHBOARD_TYPE } from "../constants";

const Scene = () => {
  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={8}>
        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">
            Analytics
          </Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <Container rounded bg="white" padding="2 4">
            <Tabs
              items={Object.values(ANALYTICS_DASHBOARD_TYPE).map(
                ({ id, title }) => ({
                  id,
                  title,
                  ContentComponent: () => (
                    <AnalyticsDashboard dashboardType={id} />
                  ),
                })
              )}
            />
          </Container>
        </Grid.Item>
      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
